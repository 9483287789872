import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {generateUUID} from "./utils/generateUUID";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//BASE SITES

import Footer from "./views/layouts/Footer";

//SITES
import Home from "./views/pages/Home/Home";
function App() {
    return (
        <BrowserRouter>
            {/* HEADER */}
            <div id="Content">
                <Routes>
                    <Route path="/" element={<Home key={generateUUID()}/>}/>
                </Routes>
            </div>
            {/* FOOTER */}
            <Footer key={generateUUID()}/>
        </BrowserRouter>
    );
}

export default App;
